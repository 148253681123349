.top {
    z-index: -1;

    background-color: var(--dark-color);

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 50px;

    height: 80vh;
}

.title {
    font-size: 5rem;
    color: var(--light-color);
    font-weight: 100;
}

.subtitle {
    font-size: 2rem;
    color: var(--primary-color);
}

.subtitle svg {
    height: 20px;
    color: var(--secondary-color);
}

.top .blinker {
    opacity: 1;
    margin-bottom: -2px;
    height: 15px;
    margin-left: 4px;
    border-right: 10px solid var(--primary-color);
    animation: blinker 0.6s steps(2, start) infinite;
}

@keyframes blinker {
    to {
        visibility: hidden;
    }
}

@media screen and (max-width: 768px) {
    .top {
        padding: 20px;
    }

    .title {
        font-size: 4rem;
    }

    .subtitle {
        font-size: 1.5rem;
    }
}