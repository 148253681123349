.sectionTitle {
    width: 40px;
    border-bottom: 4px solid var(--primary-color);
    white-space: nowrap;
}

section {
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
}

h3 {
    width: 40px;
    border-bottom: 4px solid var(--accent-color);
    white-space: nowrap;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--light-color);
}

@media screen and (max-width: 768px) {
    section {
        margin-left: 0;
        margin-right: 0;
    }

}

@media print {
    section {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}