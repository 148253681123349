.mainContent {
    margin-left: 15rem;
    margin-right: 15rem;
    margin-top: 2rem;
}

.mainContent * {
    text-align: justify;
}

@media screen and (max-width: 1100px) {
    .mainContent {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .mainContent * {
        text-align: left;
    }
}

@media print {
    .mainContent {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}