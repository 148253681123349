.top {
    padding: 4vh 8vw;
    display: flex;
    gap: 5vw;
    margin-bottom: 2rem;
}

.top p {
    text-align: justify;
}

.top img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.section {
    padding: 0 12vw 40px 12vw;
    text-align: justify;
}

.section .element {
    padding: 5px 10px 5px 150px;
    position: relative;
}

.section .element .dates {
    position: absolute;
    top: 5px;
    left: 0;
    color: var(--secondary-color);
    font-weight: bold;
    height: 100%;
}

.section .element .details {
    display: block;
    min-height: 30px;
}

.section.experience .element {
    margin-bottom: 50px;
}

.section.experience .element .details {
    margin-left: 5vw;
    padding-left: 2vw;
    border-left: 2px solid white;
}

.section.experience .element .details .title {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.section.experience .element .dates {
    width: 12vw;
}

.section.experience .element .dates .start {
    position: absolute;
    bottom: 20px;
}

.section.experience .element .details img {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: 2px solid var(--light-color);
    margin-bottom: 10px;
}

.section.experience .element .details .title::after {
    content: "\a";
    white-space: pre;
}

.section.experience .element .details .infos {
    margin-left: 20px;
}

.section.experience .element .details .infos span {
    display: block;
    margin-bottom: 5px;
}

.section.experience .element .details .infos span::before {
    content: "> ";
}

.section.experience .element .details .infos span::after {
    content: "\a";
    white-space: pre;
}

.dates .start, .dates .end {
    color: var(--secondary-color);
}

.section.experience .dates .start::after {
    content: " >\a";
    white-space: pre;
}

.section.experience .dates .end::before {
    content: "> ";
}

a.link {
    box-shadow: inset 0 0 0 0;
    margin: 0 -.25rem;
    padding: 0 .25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

a.link:hover {
    box-shadow: inset 200px 0 0 0 var(--dark-color);
}

@media screen and (max-width: 768px) {
    .top {
        flex-direction: column;
        gap: 2rem;
    }

    .top img {
        width: 100px;
        height: 100px;
    }

    .section {
        padding: 0 5vw;
    }

    .section .element {
        padding: 40px 10px;
    }

    .section .element .dates {
        top: 0;
    }

    .section.experience .element .details {
        margin-left: 0;
        padding: 5px 0;
        border-left: none;
    }

    .section.experience .element .dates {
        width: 100%;
    }

    .section.experience .element .details .infos {
        margin-left: 0;
    }

    .section.experience .element .details img {
        height: 40px;
        width: 40px;
    }
}