header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 2rem;
    background-color: var(--dark-color);
    color: white;
    position: sticky;
    top: 0;
    left: 0;
    max-width: 100vw;
    height: 75px;
    z-index: 10000;
}

header h1 {
    font-size: 1.5rem;
}

.primary {
    color: var(--primary-color);
}

.secondary {
    color: var(--secondary-color);
}

.menuIcon {
    display: none;
}

.navList {
    display: flex;
    gap: 1rem;
}

.navItem {
    list-style: none;
}

.navItem a {
    color: var(--light-color);
    text-decoration: none !important;

    font-weight: 700;

    transition: color .3s;
}

.navItem a:hover {
    color: var(--secondary-color) !important;
}

.navItem a.active {
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {
    header {
        padding: 0 1rem;
    }

    header h1 {
        font-size: 1.2rem;
    }

    .menuIcon {
        display: block;
        cursor: pointer;
    }

    .navList {
        display: none;
        flex-direction: column;
        gap: 1rem;
        position: fixed;
        top: 75px;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--light-color);
        padding: 1rem;
        margin: 0;
        z-index: 1000;
        transition: all .3s;
    }

    .navList.active {
        display: flex;
    }

    .navItem {
        text-align: center;
    }

    .navItem a {
        font-size: 1.2rem;
        color: var(--dark-color);

    }
}