.navList {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    top: 75px;
    padding: 1rem;
    background-color: #031f39;
}

.navItem {
    padding: .5rem 2rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s;
}

.navItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navItem.active {
    background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 768px) {
    .navList {
        flex-direction: column;
    }

    .navItem {
        padding: 1rem 0;
        text-align: center;
        border-radius: 0;
    }

}

@media print {
    .navList {
        display: none;
    }
}