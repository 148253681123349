.page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: -1;
}

.page svg {
    width: 100px;
    height: 100px;
}