:root {
    --dark-color: #001322;
    --primary-color: #F71735;
    --secondary-color: #41EAD4;
    --light-color: #FDFFFC;
    --accent-color: #FF9F1C;
}

body {
    margin: 0;
    font-family: 'Ubuntu', 'Noto Sans',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: var(--dark-color);
    color: var(--light-color);

    font-size: 100%;

    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: var(--light-color);
    font-weight: 700;
}

p {
    font-size: 1.2rem;
}